import React from "react";
import Home from "../components/Home"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import ErrorPage from "../pages/ErrorPage";
import Forget from "../pages/Forget";
import { SnackbarProvider } from "notistack";

const Routing = () => {
  return (
    <SnackbarProvider>
      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget" element={<Forget />} /> */}
          {/* <Route path="/reset" element={<Reset/>} /> */}
          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
        <Footer />
      </Router>
    </SnackbarProvider>

  );
};

export default Routing;
