import React from "react";

const Footer = () => {
  return (
    <div className="bg-[#222132] pb-[5px]">
      <div className="bg-[#2F2E41]  pb-[10px]">
        <div className="container mx-auto pt-[50px] ">
          <div className=" lg:flex  sm:flex-row gap-[30px]">
            <div className="basis-[30%] text-[#9291A1] md:text-4xl text-center md:text-left  ">
              <img
                className="max-w-screen-lg mx-auto lg:ml-[0.01rem]"
                src="./assets/Logo.png"
                alt=""
              />
              <div className="space-y-[10px] mt-6 font-semibold cursor-pointer ">
                <p className="text-[20px] leading-[19px]  ">
                  The most trustable delivery
                </p>
                <p className="text-[20px] leading-[19px]  ">
                  company in your area.
                </p>
              </div>
            </div>
            <div className="basis-[70%] grid grid-cols-1 lg:grid-cols-3 md:text-4xl text-center md:text-left gap-[30px]  font-semibold">
              <div className="space-y-[15px] cursor-pointer  ">
                <h1 className="text-[20px] text-[#FFFFFF] leading-[19px] font-bold"></h1>
                {/* <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal">
                  Blogs
                </p>
                <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal">
                  Movers website
                </p>
                <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal">
                  Traffic Update
                </p> */}
              </div>

              <div className="space-y-[15px] cursor-pointer">
                <h1 className="text-[20px] text-[#FFFFFF] leading-[19px] font-bold"></h1>
                <div className="gap-7">
                  <a href="#service">
                    <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal hover:text-[#FE6F23]">
                      Our Services
                    </p>
                  </a>
                  <a href="#about">
                    <p className="text-[16px] mt-6 text-[#9291A1] leading-[19px] font-normal hover:text-[#FE6F23]">
                      About Us
                    </p>
                  </a>
                  {/* <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal ">
                  Documents
                </p> */}
                </div>
              </div>
              <div className="space-y-[15px] cursor-pointer ">
                <h1 className="text-[20px] text-[#FFFFFF] leading-[19px] font-bold"></h1>
                <div className="gap-10">
                  <a href="#why">
                    <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal hover:text-[#FE6F23]">
                      Why Choose Us
                    </p>
                  </a>
                  {/* <p className="text-[16px] text-[#9291A1] leading-[19px] font-normal">
                  Contact US
                </p> */}
                  <a href="#goal">
                    <p className="text-[16px] mt-6 text-[#9291A1] leading-[19px] font-normal hover:text-[#FE6F23]">
                      Our Goal
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" mx-auto flex items-center justify-center py-[15px] text-[#DBDBE9]">
        <div>
          <p className="text-[20px] leading-[19px] font-semibold mt-[20px] ">
            All rights Reserved © Your Company, 2021 @threeparcel
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
