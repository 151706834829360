import React from "react";

const Services = () => {
  return (
    <div className="bg-[#FAFAFA] pb-[80px]">
      <div>
        <div className="pt-[30px]">
          <img className="" src="./assets/Vectors.png" alt="" />
        </div>
        <div
          className="flex items-center justify-center mx-auto flex-col lg:mt-[-60px] "
          id="service"
        >
          <h1 className="lg:text-[85px]  font-bold text-[#EBEBEB]">
            Our Services
          </h1>
          <h1 className=" text-[54px] font-bold text-[#0E1D34] lg:pt-[-9] ">
            Our Services
          </h1>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-4 mt-[40px] gap-[30px] px-3 ">
          <div className=" shadow-lg pt-[50px]  rounded-lg pb-[40px] ">
            <div className="flex items-center justify-center">
              <img src="./assets/Circleblue.png" alt="" />
            </div>
            <div className="flex items-center justify-center mt-[-90px]">
              <img src="./assets/Same.png" alt="" />
            </div>
            <h3 className="text-[25px] py-2 pt-10 font-semibold text-center">
              Same day delivery
            </h3>
            <p className="text-[16px] flex text-center justify-center text-[#7B7A8B] px-8 leading-6 pt-6">
              We Deliver your Goods on same day with promise and RTO the product
              on same day (this service belongs to Schedule orders )
            </p>
            <a className="flex justify-center items-center" href="#contact">
              <button className=" mt-[10px] items-center text-[#fff] bg-[#FE6F23] h-[50px] w-[160px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                View More
              </button>
            </a>
          </div>
          <div className=" shadow-lg pt-[50px]  rounded-lg pb-[40px] ">
            <div className="flex items-center justify-center">
              <img src="./assets/Circleblue.png" alt="" />
            </div>
            <div className="flex items-center justify-center mt-[-85px]">
              <img src="./assets/Express.png" alt="" />
            </div>
            <h3 className="text-[25px] py-2 pt-[50px]  font-semibold text-center">
              Express delivery
            </h3>
            <p className="text-[16px] flex text-center justify-center text-[#7B7A8B] px-8 leading-6 pt-6">
              Offering door delivery around the city, where your products will
              be at your doorstep within 70 minutes.
            </p>
            <a className="flex justify-center items-center" href="#contact">
              <button className=" mt-[30px] items-center text-[#fff] bg-[#FE6F23] h-[50px] w-[160px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                View More
              </button>
            </a>
          </div>
          <div className=" shadow-lg pt-[50px]  rounded-lg pb-[40px] ">
            <div className="flex items-center justify-center ">
              <img src="./assets/Circleblue.png" alt="" />
            </div>
            <div className="flex items-center justify-center mt-[-100px]">
              <img src="./assets/Hyperlocal.png" alt="" />
            </div>
            <h3 className="text-[25px] py-2 pt-10  font-semibold text-center">
              Hyperlocal
            </h3>
            <p className="text-[16px] flex text-center justify-center text-[#7B7A8B] px-8 leading-6 pt-6">
              Providing dedicated riders in range of Rs.700-800
            </p>
            <p className="px-4 flex text-center justify-center text-[#c2c2c4] text-[15px]">
              * Rate may vary according to the requirement
            </p>
            <a className="flex justify-center items-center" href="#contact">
              <button className=" mt-[30px] items-center text-[#fff] bg-[#FE6F23] h-[50px] w-[160px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                View More
              </button>
            </a>
          </div>
          <div className=" shadow-lg pt-[50px]  rounded-lg pb-[40px] ">
            <div className="flex items-center justify-center">
              <img src="./assets/Circleblue.png" alt="" />
            </div>
            <div className="flex items-center justify-center mt-[-94px]">
              <img src="./assets/api.png" alt="" />
            </div>
            <h3 className="text-[25px] py-2 pt-9  font-semibold text-center">
              API integration
            </h3>
            <p className="text-[16px]  flex text-center justify-center text-[#7B7A8B] px-8 leading-6 pt-6">
              Now you can use our Service without placing order in our System .
              Order will be fetch through Api integration.
            </p>
            <a className="flex justify-center items-center" href="#contact">
              <button className=" mt-[30px] items-center text-[#fff] bg-[#FE6F23] h-[50px] w-[160px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                View More
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
