import React, { useState } from "react";

const Whychoose = () => {
  const [isLast, setIsLast] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [isDedicated, setIsDedicated] = useState(false);
  const [isMoto, setIsMoto] = useState(false);
  const [isTran, setIsTran] = useState(false);
  const [isHass, setIsHass] = useState(false);
  return (
    <div className="bg-[#FAFAFA]  pb-[80px]">
      <div>
        <div
          className="flex items-center justify-center mx-auto flex-col "
          id="why"
        >
          <h1 className=" lg:text-[85px] sm:text-[50px] font-bold text-[#EBEBEB]">
            Why Choose Us
          </h1>
          <h1 className=" text-[40px]  font-bold text-[#0E1D34] ">
            Why Choose us
          </h1>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 mt-[40px] lg:gap-[20%]">
          <div>
            <img src="./assets/Whychoose.png" alt="" />
          </div>
          <div
            className="lg:pt-[20px] sm:pt-[-40px]
          "
          >
            <ul className="mt-[50px] space-y-[15px]  text-[26px] font-semibold">
              <div className="flex gap-4">
                <img className="h-[25px] mt-3 " src="./assets/Why.png" alt="" />
                <li>Last mile delivery</li>
                <button
                  onClick={() => setIsLast((prev) => !prev)}
                  className="relative inline-block "
                >
                  <img
                    className="h-[15px] mt-1 "
                    src="./assets/Polygon2.png"
                    alt=""
                  />
                  {isLast && (
                    <div class="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <p className="text-[16px] text-[#1C1C1C]  px-3">
                          Delivering product from nearest Distribution hub to
                          the Final Destination.
                        </p>
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <div className="flex gap-4">
                <img className="h-[25px] mt-3 " src="./assets/Why.png" alt="" />
                <li>Dedicated Services</li>
                <button
                  onClick={() => setIsDedicated((prev) => !prev)}
                  className="relative inline-block "
                >
                  <img
                    className="h-[15px] mt-1 "
                    src="./assets/Polygon2.png"
                    alt=""
                  />
                  {isDedicated && (
                    <div class="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <p className="text-[16px] text-[#1C1C1C]  px-3">
                          Business customer can opt dedicated riders by which
                          they can grow their business (B2B Services)'
                        </p>
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <div className="flex gap-4">
                <img className="h-[25px] mt-3 " src="./assets/Why.png" alt="" />
                <li>Low Delivery fee</li>
                <button
                  onClick={() => setIsTran((prev) => !prev)}
                  className="relative inline-block text-left"
                >
                  <img
                    className="h-[15px]  relative inline-block text-left "
                    src="./assets/Polygon2.png"
                    alt=""
                  />
                  {isTran && (
                    <div class="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <p className="text-[16px] px-3 ">
                          Changes of the order on-the-go.
                        </p>
                        <ul className="text-[16px] text-[#1C1C1C] px-3  inline-block text-left">
                          <li> ● online tracking of courier.</li>
                          <li> ● real time updates.</li>
                          <li> ● history and statistics.</li>
                          <li> ● clearly calculated price.</li>
                        </ul>
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <div className="flex gap-4">
                <img className="h-[25px] mt-3 " src="./assets/Why.png" alt="" />
                <li>Live Tracking</li>
                <button
                  onClick={() => setIsLive((prev) => !prev)}
                  className="relative inline-block "
                >
                  <img
                    className="h-[15px] mt-1 relative inline-block text-left "
                    src="./assets/Polygon2.png"
                    alt=""
                  />
                  {isLive && (
                    <div class="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <p className="text-[16px] text-[#1C1C1C]  px-3">
                          Location of courier can be tracked at which location
                          they are still.
                        </p>
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <div className="flex gap-4">
                <img className="h-[25px] mt-3 " src="./assets/Why.png" alt="" />
                <li>Payment Method</li>
                <button
                  onClick={() => setIsMoto((prev) => !prev)}
                  className="relative inline-block "
                >
                  <img
                    className="h-[15px] mt-1 relative inline-block text-left "
                    src="./assets/Polygon2.png"
                    alt=""
                  />
                  {isMoto && (
                    <div class="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <p className="text-[16px] text-[#1C1C1C]  px-3">
                          UPI ,Cash, Card, Wallet.
                        </p>
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <div className="flex gap-4">
                <img className="h-[25px] mt-3 " src="./assets/Why.png" alt="" />
                <li>Hassle free Support System</li>
                <button
                  onClick={() => setIsHass((prev) => !prev)}
                  className="relative inline-block "
                >
                  <img
                    className="h-[15px] mt-1 relative inline-block text-left "
                    src="./assets/Polygon2.png"
                    alt=""
                  />
                  {isHass && (
                    <div class="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <p className="text-[16px] text-[#1C1C1C]  px-3">
                          Chat Support , Whatsapp help Service , Customer call
                          centre and Key Account Manager for Corporate Clients
                        </p>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whychoose;
