import React, { useState, useEffect, useRef } from 'react'
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";


const Nav = () => {

  const [toggle, setToggle] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  return (
    <div className="" ref={menuRef}>
      <div className="container mx-auto">
        <div className="flex items-center  justify-between pt-[29px] px-4 ">
          <div className="flex items-center gap-[16px]">
            <img src="./assets/Logo.png" alt="" />
          </div>

          <nav className=" hidden md:flex items-center gap-[44px] ">
            <ul className="flex items-center  gap-[25px] text-[#817382] text-[16px] line-[24px] ">
              <li className="text-[#FE6F23] hover:bg-white py-2 px-4 hover:border hover:border-[#FE6F23] rounded-[50px]">
                <Link to="/">Home</Link>
              </li>
              <li className="text-[#FE6F23] hover:bg-white py-2 px-4 hover:border hover:border-[#FE6F23] rounded-[50px]">
                <a href="#service">Our Services</a>
              </li>
              <li className="text-[#FE6F23] hover:bg-white py-2 px-4 hover:border hover:border-[#FE6F23] rounded-[50px]">
                <a href="#about">About Us</a>
              </li>
              <li className="text-[#FE6F23] hover:bg-white py-2 px-4 hover:border hover:border-[#FE6F23] rounded-[50px]">
                <a href="#why">Why Choose Us</a>
              </li>
              <li className="text-[#FE6F23] hover:bg-white py-2 px-4 hover:border hover:border-[#FE6F23] rounded-[50px]">
                <a href="#goal">Our Goal</a>
              </li>

              {/* <li>
              <img src="./assets/icon.png" alt="" />
            </li> */}
            </ul>
            <a href="#contact">
              <button className=" text-[#fff] bg-[#FE6F23] h-[50px] w-[151px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                Contact us
              </button>
            </a>
            {/* <Link to="/login">
              <button className=" text-[#fff] bg-[#FE6F23] h-[50px] w-[160px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                Order Delivered
              </button>
            </Link> */}
          </nav>
          {toggle ? (
            <MdClose
              onClick={() => setToggle(!toggle)}
              className=" md:hidden block text-[24px] z-10"
            />
          ) : (
            <FiMenu
              onClick={() => setToggle(!toggle)}
              className=" md:hidden block text-[24px] z-10"
            />
          )}

          <nav
            className={`duration-300 md:hidden flex flex-col fixed text-center bg-white text-black top-0 w-[100%] h-screen
         gap-8  font-semibold ${toggle ? `right-[0]` : `right-[-100%]`} `}
          >
            <ul className="mt-[40%]">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#service" onClick={() => setToggle(false)}>
                  Our services
                </a>
              </li>
              <a href="#about" onClick={() => setToggle(false)}>
                About Us
              </a>
              <li>
                <a href="#why" onClick={() => setToggle(false)}>
                  Why choose us
                </a>
              </li>
              <li>
                <a href="#goal" onClick={() => setToggle(false)}>
                  Our Goal
                </a>
              </li>
              {/* <li className="text-center">
              <img src="./assets/icon.png" alt="" />
            </li> */}
            </ul>
            <a href="#contact">
              <button className="  text-[#fff] bg-[#FE6F23] h-[50px] w-[151px]  font-semibold py-2 px-4 border  rounded-[50px] ">
                Contact us
              </button>
            </a>
            {/* <a href="#">
            <button className=" text-[#fff] bg-[#FE6F23] h-[50px] w-[160px]  font-semibold py-2 px-4 border  rounded-[50px] ">
              Order Delivered
            </button>
          </a> */}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Nav;
