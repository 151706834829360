import React, { useState, useEffect, useRef } from "react";
import Aboutus from "./Aboutus";
import Contact from "./Contact";
import Header from "./Header";
import Mobile from "./Mobile";
import Ouraim from "./Ouraim";
import Services from "./Services";
import Tgoods from "./Tgoods";
import Whychoose from "./Whychoose";
import Gototop from "./Gototop";

const Home = () => {
  return (
    <>
      <Header />
      <Aboutus />
      <Services />
      <Tgoods />
      <Whychoose />
      <Ouraim />
      <Mobile />
      <Contact />
      <Gototop />
    </>
  );
};

export default Home;
