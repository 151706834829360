import React, { useEffect, useState } from "react";
import { FaArrowUp } from 'react-icons/fa';
import styled from 'styled-components';


const Gototop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goTotop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const listenToScroll = () => {
    let heightToHidden = 20;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  return (

    <Wrapper>
      {isVisible && (
        <div className='top-btn' onClick={goTotop}>
          <FaArrowUp className='top-btn--icon' />
        </div>
      )}
    </Wrapper>
  )
}
export default Gototop

const Wrapper = styled.section`
display:flex;
justify-content: center;
align-items:center;
.top-btn{
  font-size:2.4rem;
  width:3rem;
  height:3rem;
  color:#fff;
  background-color:#FE6F23;
  border-radius:50%;
  position:fixed;
  bottom:3rem;
  right:2rem;
  z-index:999;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
  &--icon {
    font-size:20px;
      animation: gototop 1.2s linear infinite alternate-reverse;
    }
    @keyframes gototop {
      0% {
        transform: translateY(-0.5rem);
      }
      100% {
        transform: translateY(0.1rem);
      }
    }
}
`;
