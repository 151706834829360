import React from "react";

const Aboutus = () => {
  return (
    <div className="bg-white pb-[80px] ">
      <div className="container mx-auto ">
        <div>
          <img
            className="lg:float-right sm:float-left"
            src="./assets/Vector.png"
            alt=""
          />
        </div>
        <div
          className="flex items-center justify-center lg:ml-[10%] mx-auto flex-col"
          id="about"
        >
          <h1 className=" lg:text-[100px] sm:text-[54px] font-bold text-[#F9F9F9] ">
            About Us
          </h1>
          <h1 className=" text-[54px] font-bold text-[#0E1D34] pt-[-9]">
            About Us
          </h1>
        </div>
        <div className=" shadow-md pt-[50px] pb-[50px] pl-[35px] rounded-md px-3">
          <div className="text-center leading-relaxed  text-base">
            <p className="text-[24px] text-[#000000]">
              We can deliver exactly when you want and can start processing your
              order almost immediately after we receive it, or &nbsp;
              <b>we can deliver</b>
            </p>
            <p className="text-[24px] text-[#000000] ">
              <b>
                on a specific day and time. <br />
              </b>{" "}
              We have a custom app by which customers can place orders and send
              their goods (documents, clothes,
            </p>
            <p className="text-[24px] text-[#000000] ">
              electronic items, household items, sports equipment ) from one
              place to another. <br /> We have an app for couriers to make our
              deliveries as
            </p>
            <p className="text-[24px] text-[#000000] ">
              fast as possible. When you place an order, our system finds the
              nearest available courier (there are always couriers available),
              who
            </p>
            <p className="text-[24px] text-[#000000] ">
              will immediately start fulfilling the order using the quickest
              route that we have calculated.
              <br /> We will also work on the Last Mile project, Same
            </p>
            <p className="text-[24px] text-[#000000] ">
              Day Delivery, and Next Day Delivery.
            </p>
          </div>
          {/* <img className="mt-[10%]" src="./assets/Rectangle.png" alt="" /> */}
          <div className=" mt-[4%] grid grid-cols-1 lg:grid-cols-3  text-center ">
            <div>
              <h1 className="text-[#FE6F23] text-[78px] font-bold">2022</h1>
              <h2 className="text-[25px] text-[#000000] font-semibold">
                Company Registration
              </h2>
              <p className="text-[18px] text-[#000000]">
                Done 10k Orders in Month
              </p>
              <p className="text-[18px] text-[#000000]">with 500 riders</p>
            </div>
            <div>
              <h1 className="text-[#FE6F23] text-[78px] font-bold">2023</h1>
              <h2 className="text-[25px] text-[#000000] font-semibold">Goal</h2>
              <p className="text-[18px] text-[#000000]">
                2M order per month with
              </p>
              <p className="text-[18px] text-[#000000]">500k riders</p>
            </div>
            <div>
              <h1 className="text-[#FE6F23] text-[78px] font-bold">2024</h1>
              <h2 className="text-[25px] text-[#000000] font-semibold">
                Expansion of Services
              </h2>
              <p className="text-[18px] text-[#000000]">
                We will expand first in all Tier 1 cities, then we
              </p>
              <p className="text-[18px] text-[#000000]">
                go on to Tier 2 and Tier 3 cities. This year, we are believing
              </p>
              <p className="text-[18px] text-[#000000]">
                that our company will become India’s 1st largest
              </p>
              <p className="text-[18px] text-[#000000]">logistics company.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
