import React from "react";

const Tgoods = () => {
  return (
    <div className="bg-[#F5F5F5] pb-[80px]">
      <div className="pt-[30px]">
        <div className="flex items-center justify-center mx-auto flex-col mt-[-30px]">
          <h1 className=" lg:text-[85px] sm:text-[30px] font-bold text-[#EBEBEB]">
            Types of goods
          </h1>
          <h1 className=" text-[45px] font-bold text-[#0E1D34] lg:mt-[-30px]">
            Types of Goods,We are Delivering
          </h1>
        </div>
      </div>
      <div className=" container mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-4 mt-[40px] lg:gap-[8%] px-3">
          <div>
            <div class="border shadow-lg rounded-2xl py-[10%]">
              <div className="flex justify-center items-center ">
                <img
                  className="pt-[20px] pb-[20px] "
                  src="./assets/Clothes.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className="text-center text-[20px] mt-2 font-semibold">
              Clothes and shoes
            </h2>
          </div>
          <div>
            <div className=" border shadow-lg   rounded-2xl py-[10%] ">
              <div className="flex items-center justify-center  ">
                <img
                  className="pt-[30px] pb-[30px]"
                  src="./assets/Tfood.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className=" text-center text-[20px] mt-2 font-semibold">
              Foods & grocery
            </h2>
          </div>
          <div>
            <div className=" border shadow-lg rounded-2xl py-[10%]">
              <div className="flex items-center justify-center  ">
                <img
                  className="pt-[16px] pb-[16px]"
                  src="./assets/Flower.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className=" text-center text-[20px] mt-2 font-semibold">
              Flower & Gifts
            </h2>
          </div>
          <div>
            <div className=" border shadow-lg rounded-2xl py-[10%]">
              <div className="flex items-center justify-center">
                <img
                  className="pt-[38px] pb-[38px]"
                  src="./assets/E-shop.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className="text-center text-[20px] mt-2 font-semibold">
              E-shop items
            </h2>
          </div>
          <div>
            <div className=" border shadow-lg rounded-2xl py-[10%]">
              <div className="flex items-center justify-center">
                <img
                  className="pt-[20px] pb-[20px]"
                  src="./assets/Hardware.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className=" text-center text-[20px] mt-2 font-semibold">
              Hardware
            </h2>
          </div>
          <div className="">
            <div className=" border shadow-lg rounded-2xl py-[10%]">
              <div className="flex items-center justify-center">
                <img
                  className="pt-[30px] pb-[30px]"
                  src="./assets/Documents.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className=" text-center text-[20px] mt-2 font-semibold">
              Documents
            </h2>
          </div>
          <div>
            <div className=" border shadow-lg rounded-2xl  py-[10%]">
              <div className="flex items-center justify-center  ">
                <img
                  className="pt-[15px] pb-[15px]"
                  src="./assets/TPharmacy.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className=" text-center text-[20px] mt-2 font-semibold">
              Pharmacy
            </h2>
          </div>
          <div>
            <div className=" border shadow-lg rounded-2xl py-[10%]">
              <div className="flex items-center justify-center  ">
                <img
                  className="pt-[20px] pb-[20px]"
                  src="./assets/Tcake.png"
                  alt=""
                />
              </div>
            </div>
            <h2 className=" text-center text-[20px] mt-2 font-semibold">
              Cake
            </h2>
          </div>
          {/* <div>
            <div className=" border shadow-lg  rounded-2xl py-[41px] px-10">
              <div className="flex items-center justify-center   ">
                <img src="./assets/Groceries.png" alt="" />
              </div>
            </div>
            <h2 className=" text-center text-[20px]">Groceries</h2>
          </div> */}
        </div>
      </div>
      {/* <div className=" lg:pl-[16%] ">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 lg:grid-cols-5 lg:mt-[50px] lg:gap-[8%] px-4">
            <div className="">
              <div className=" border shadow-lg rounded-2xl lg:py-[40px] py-[30px] px-10 ">
                <div className="flex items-center justify-center">
                  <img src="./assets/Documents.png" alt="" />
                </div>
              </div>
              <h2 className=" text-center text-[20px]">Documents</h2>
            </div>
            <div>
              <div className=" border shadow-lg rounded-2xl py-[40px] px-10 ">
                <div className="flex items-center justify-center  ">
                  <img src="./assets/TPharmacy.png" alt="" />
                </div>
              </div>
              <h2 className=" text-center text-[20px]">Pharmacy</h2>
            </div>
            <div>
              <div className=" border shadow-lg rounded-2xl py-[35px] px-10">
                <div className="flex items-center justify-center  ">
                  <img src="./assets/Tcake.png" alt="" />
                </div>
              </div>
              <h2 className=" text-center text-[20px]">cake</h2>
            </div>
            <div>
              <div className=" border shadow-lg  rounded-2xl py-[41px] px-10">
                <div className="flex items-center justify-center   ">
                  <img src="./assets/Groceries.png" alt="" />
                </div>
              </div>
              <h2 className=" text-center text-[20px]">Groceries</h2>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Tgoods;
